import { Box, Button, Container, Flex, Image, Link, List, ListItem, Text } from '@chakra-ui/react'
import { useEffect, useState, VFC } from 'react'
import Reveal from 'react-awesome-reveal'
import { navigate } from 'vite-plugin-ssr/client/router'

import { cFadeMenu } from '~/assets/animations/CFadeMenu'
import hamburgerMenu from '~/assets/image/header/hamburger-menu.svg'
import HamburgerMenuClose from '~/assets/image/header/hamburger-menu-close.svg'
import headerLogo from '~/assets/image/header/header-logo.svg'
import mokuwellBanner from '~/assets/image/header/mokuwell-banner.png'

export type GHeaderViewProps = {
    openModal: boolean
    onClickMenu: () => void
    onClickMovePage: (path: string) => void
} & GHeaderProps

export const GHeaderView: VFC<GHeaderViewProps> = ({ openModal, onClickMenu, onClickMovePage }) => {
    useEffect(() => {
        document.body.style.overflow = openModal ? 'hidden' : 'unset'
    }, [openModal])

    const PageLinks = () => (
        <Box>
            <Container display={{ base: 'block', lg: 'none' }} height="48px" mt="10px">
                <Flex height="100%" justifyContent="end" direction="row">
                    <Image src={HamburgerMenuClose} alt="menu" onClick={onClickMenu} width="20px" />
                </Flex>
            </Container>
            <List
                display="flex"
                gap={{ base: '16px', lg: '14px', xl: '40px' }}
                flexDirection={{ base: 'column', lg: 'row' }}
                position={{ base: 'absolute', lg: 'static' }}
                top={{ base: '50%', lg: '0' }}
                left={{ base: '35%', lg: '0' }}
                transform={{ base: 'translate(-50%, -50%)', lg: 'translate(0, 0)' }}>
                <ListItem display={'flex'} alignItems={'center'}>
                    <Box
                        borderColor={'transparent'}
                        display={'flex'}
                        transition="0.5s"
                        cursor="pointer"
                        position="relative"
                        _after={{
                            display: { base: 'none', lg: 'block' },
                            pos: 'absolute',
                            content: '""',
                            bottom: '3px',
                            width: '100%',
                            borderBottom: 'solid 4px #000',
                            transform: 'scaleX(0)',
                            transition: 'transform 500ms',
                            transformOrigin: 'left',
                        }}
                        _hover={{ _after: { transform: 'scaleX(1)', transformOrigin: 'left' } }}
                        onClick={() => onClickMovePage('/')}>
                        <Text fontFamily="'Din Condensed W05 Demi Bold'" fontSize={{ base: '32px', lg: '22px', xl: '24px' }}>
                            TOP
                        </Text>
                    </Box>
                </ListItem>
                <ListItem display={'flex'}>
                    <Box
                        display={'flex'}
                        position="relative"
                        alignItems="center"
                        cursor="pointer"
                        _after={{
                            display: { base: 'none', lg: 'block' },
                            pos: 'absolute',
                            content: '""',
                            bottom: '3px',
                            width: '100%',
                            borderBottom: 'solid 4px #000',
                            transform: 'scaleX(0)',
                            transition: 'transform 500ms',
                            transformOrigin: 'left',
                        }}
                        _hover={{ _after: { transform: 'scaleX(1)', transformOrigin: 'left' } }}
                        onClick={() => onClickMovePage('/#our-business')}>
                        <Text fontFamily="'Din Condensed W05 Demi Bold'" fontSize={{ base: '32px', lg: '22px', xl: '24px' }}>
                            OUR BUSINESS
                        </Text>
                    </Box>
                </ListItem>
                <ListItem display={'flex'}>
                    <Box
                        borderColor={'transparent'}
                        display={'flex'}
                        alignItems="center"
                        transition="0.5s"
                        cursor="pointer"
                        position="relative"
                        _after={{
                            display: { base: 'none', lg: 'block' },
                            pos: 'absolute',
                            content: '""',
                            bottom: '3px',
                            width: '100%',
                            borderBottom: 'solid 4px #000',
                            transform: 'scaleX(0)',
                            transition: 'transform 500ms',
                            transformOrigin: 'left',
                        }}
                        _hover={{ _after: { transform: 'scaleX(1)', transformOrigin: 'left' } }}
                        onClick={() => onClickMovePage('/company')}>
                        <Text fontFamily="'Din Condensed W05 Demi Bold'" fontSize={{ base: '32px', lg: '22px', xl: '24px' }}>
                            COMPANY
                        </Text>
                    </Box>
                </ListItem>
                <ListItem display={'flex'}>
                    <Box
                        borderColor={'transparent'}
                        display={'flex'}
                        alignItems="center"
                        transition="0.5s"
                        cursor="pointer"
                        position="relative"
                        _after={{
                            display: { base: 'none', lg: 'block' },
                            pos: 'absolute',
                            content: '""',
                            bottom: '3px',
                            width: '100%',
                            borderBottom: 'solid 4px #000',
                            transform: 'scaleX(0)',
                            transition: 'transform 500ms',
                            transformOrigin: 'left',
                        }}
                        _hover={{ _after: { transform: 'scaleX(1)', transformOrigin: 'left' } }}
                        onClick={() => onClickMovePage('/news')}>
                        <Text fontFamily="'Din Condensed W05 Demi Bold'" fontSize={{ base: '32px', lg: '22px', xl: '24px' }}>
                            NEWS RELEASE
                        </Text>
                    </Box>
                </ListItem>
                <ListItem display={'flex'}>
                    <Box
                        borderColor={'transparent'}
                        display={'flex'}
                        alignItems="center"
                        transition="0.5s"
                        cursor="pointer"
                        position="relative"
                        _after={{
                            display: { base: 'none', lg: 'block' },
                            pos: 'absolute',
                            content: '""',
                            bottom: '3px',
                            width: '100%',
                            borderBottom: 'solid 4px #000',
                            transform: 'scaleX(0)',
                            transition: 'transform 500ms',
                            transformOrigin: 'left',
                        }}
                        _hover={{ _after: { transform: 'scaleX(1)', transformOrigin: 'left' } }}
                        onClick={() => onClickMovePage('/contact')}>
                        <Text fontFamily="'Din Condensed W05 Demi Bold'" fontSize={{ base: '32px', lg: '22px', xl: '24px' }}>
                            CONTACT
                        </Text>
                    </Box>
                </ListItem>
                <ListItem display={'flex'}>
                    <Box
                        as="a"
                        href="https://en-gage.net/mec-industry/"
                        borderColor={'transparent'}
                        display={'flex'}
                        alignItems="center"
                        transition="0.5s"
                        cursor="pointer"
                        position="relative"
                        _after={{
                            display: { base: 'none', lg: 'block' },
                            pos: 'absolute',
                            content: '""',
                            bottom: '3px',
                            width: '100%',
                            borderBottom: 'solid 4px #000',
                            transform: 'scaleX(0)',
                            transition: 'transform 500ms',
                            transformOrigin: 'left',
                        }}
                        _hover={{ _after: { transform: 'scaleX(1)', transformOrigin: 'left' } }}>
                        <Text fontFamily="'Din Condensed W05 Demi Bold'" fontSize={{ base: '32px', lg: '22px', xl: '24px' }}>
                            RECRUIT
                        </Text>
                    </Box>
                </ListItem>
                <ListItem display={'flex'} my={{ base: '10px', lg: 0 }}>
                    <Button
                        as={'a'}
                        href="/building-material"
                        backgroundColor={'dark.500'}
                        borderRadius={{ base: '40px', md: '34px' }}
                        color={'light'}
                        fontSize={{ base: '2rem', lg: '1.375rem', xl: '1.5rem' }}
                        fontFamily={'Din Condensed W05 Demi Bold'}
                        boxSize={'100%'}
                        py={{ base: '0.625rem', lg: 0, xl: 0 }}
                        w={'auto'}>
                        PRODUCTS
                    </Button>
                </ListItem>
                <ListItem display={'flex'} mt={{ base: '10px', lg: 0 }}>
                    <Link
                        href="https://mokuwellhouse.jp/"
                        target="_blank"
                        rel="noopener noreferrer"
                        borderColor={'transparent'}
                        display={'flex'}
                        alignItems="center"
                        _focus={{ boxShadow: 'none' }}>
                        <Image
                            height={{ base: '48px', lg: '44px' }}
                            boxSize={'100%'}
                            src={mokuwellBanner}
                            alt="Mokuwell House"
                            width={{ base: '78.8px', lg: 'auto' }}
                        />
                    </Link>
                </ListItem>
            </List>
        </Box>
    )

    const [scrollState, setScrollState] = useState(true)

    useEffect(() => {
        if (window.location.pathname !== '/') {
            setScrollState(false)
            return
        }

        const listener = () => {
            const scrolled = document?.scrollingElement?.scrollTop ?? 0
            if (scrolled >= 220) {
                if (scrollState) setScrollState(false)
            } else if (!scrollState) setScrollState(true)
        }
        document.addEventListener('scroll', listener)
        return () => {
            document.removeEventListener('scroll', listener)
        }
    }, [scrollState])

    return (
        <>
            <Container
                data-testid="g-header"
                as="header"
                position="fixed"
                top="0"
                maxWidth="auto"
                height={{ base: '48px', lg: '73px' }}
                transition="0.5s"
                backgroundColor={scrollState ? 'transparent' : 'background'}
                zIndex="10">
                <Flex height="100%" margin="0 auto" alignItems="center" direction="row">
                    {/* TOPアイコン */}
                    <Box
                        as="h1"
                        height="100%"
                        display="flex"
                        width={{ base: '157px', lg: '220px' }}
                        alignItems="center"
                        cursor="pointer"
                        onClick={() => onClickMovePage('/')}>
                        <Image src={headerLogo} alt="MEC Industry" />
                    </Box>
                    {/* ハンバーガーメニュー */}
                    <Box display={{ base: 'block', lg: 'none' }} ml="auto" onClick={onClickMenu} transition="1s">
                        <Image src={hamburgerMenu} alt="menu" />
                    </Box>
                    {/* メニュー */}
                    <Box display={{ base: 'none', lg: 'block' }} ml="auto">
                        <PageLinks />
                    </Box>
                </Flex>
                {/* SPメニュー */}
                {openModal && (
                    // <Reveal keyframes={cFadeUp}>
                    <Reveal keyframes={cFadeMenu} duration={500}>
                        <Box
                            display={{ base: 'block', lg: 'none' }}
                            position="fixed"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            backgroundColor={'background'}>
                            <PageLinks />
                        </Box>
                    </Reveal>
                    // </Reveal>
                )}
            </Container>
        </>
    )
}

export type GHeaderProps = {}

export const GHeader: VFC<GHeaderProps> = (props) => {
    /** Util */
    /** State */
    const [openModal, setOpenModal] = useState(false)
    /** Effect */
    /** Event */
    const onClickMenu = () => {
        setOpenModal(!openModal)
    }

    const onClickMovePage = async (path: string) => {
        setOpenModal(false)
        await navigate(path)
    }
    return <GHeaderView {...props} openModal={openModal} onClickMenu={onClickMenu} onClickMovePage={onClickMovePage} />
}
